import React from 'react';
import './chevron-line.scss';
import ChevronDesktop from '../chevron-desktop/chevron-desktop';
import ChevronMobile from '../chevron-mobile/chevron-mobile';
import { ChevronLineProps } from '../../shared/props-types/chevron-line.props';

const ChevronLine = ({ chevrons }: ChevronLineProps) => {
  return (
    <>
      <div className="chevron-line desktop">
        {chevrons
          .filter((chevron) => chevron.isVisible)
          .map((chevron) => (
            <ChevronDesktop
              key={'chevron-desktop-' + chevron.id}
              chevron={chevron}
            />
          ))}
      </div>
      <div className="chevron-line mobile">
        {chevrons
          .filter((chevron) => chevron.isVisible)
          .reverse()
          .map((chevron) => (
            <ChevronMobile
              key={'chevron-mobile-' + chevron.id}
              chevron={chevron}
            />
          ))}
      </div>
    </>
  );
};

export default ChevronLine;
