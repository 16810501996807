import React from 'react';
import './chevron-desktop.scss';
import ChevronDesktopTopPart from './chevron-desktop-top-part/chevron-desktop-top-part';
import { ChevronProps } from '../../shared/props-types/chevron.props';

const ChevronDesktop = ({ chevron }: ChevronProps) => {
  return (
    <div className="chevron-desktop">
      <ChevronDesktopTopPart chevron={chevron} />
      <div className="chevron-desktop-bottom-part">
        <ChevronDesktopTopPart chevron={chevron} />
      </div>
    </div>
  );
};

export default ChevronDesktop;
