import React, { useState } from 'react';
import './chevron-chart.scss';
import ChevronLine from '../chevron-line/chevron-line';
import { ChevronModel } from '../../shared/models/chevron.model';

const ChevronChart = () => {
  const [chevrons, setChevrons] = useState<ChevronModel[]>([
    { id: 0, color: generateRandomColor(), isVisible: false },
  ]);

  const hideChevron = (id: number) => {
    const newChevrons = chevrons.slice();
    newChevrons.find((chevron) => chevron.id === id)!.isVisible = false;
    setChevrons(newChevrons);
  };

  const showChevron = (id: number) => {
    const newChevrons = chevrons.slice();
    newChevrons.find((chevron) => chevron.id === id)!.isVisible = true;
    setChevrons(newChevrons);
  };

  const pushChevron = () => {
    const newChevrons = chevrons.slice();
    newChevrons.push({
      id: newChevrons.length,
      color: generateRandomColor(),
      isVisible: false,
    });
    setChevrons(newChevrons);
  };

  const isCheckboxUncheckable = (id: number) => {
    const checkedChevrons = chevrons.filter((chevron) => chevron.isVisible);
    return checkedChevrons.length === 1 && checkedChevrons[0].id === id;
  };

  return (
    <div className="chevron-chart">
      <form>
        {chevrons.map((chevron) => (
          <input
            key={'chevron-chart-checkbox-' + chevron.id}
            type="checkbox"
            className="chevron-chart-checkbox"
            // could be left out for current initial state, but would be needed,
            // if an initial state would contain some visible chevrons
            // checked={chevron.isVisible}
            disabled={chevron.isVisible && isCheckboxUncheckable(chevron.id)}
            onChange={(event) => {
              if (event.target.checked) {
                showChevron(chevron.id);
                if (chevron.id === chevrons.length - 1) {
                  pushChevron();
                }
              } else {
                hideChevron(chevron.id);
              }
            }}
          />
        ))}
      </form>
      <ChevronLine chevrons={chevrons} />
    </div>
  );
};

const generateRandomColor = (): string => {
  const round = Math.round;
  const random = Math.random;
  const base = 255;

  return `rgba(${round(random() * base)}, ${round(random() * base)}, ${round(random() * base)}, 1)`;
};

export default ChevronChart;
