import React from 'react';
import './chevron-mobile-left-part.scss';
import { ChevronProps } from '../../../shared/props-types/chevron.props';

const ChevronMobileLeftPart = ({ chevron }: ChevronProps) => {
  const { color } = chevron;

  return (
    <div
      className="chevron-mobile-left-part"
      style={{ backgroundColor: color }}
    ></div>
  );
};

export default ChevronMobileLeftPart;
