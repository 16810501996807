import React from 'react';
import './chevron-mobile.scss';
import ChevronMobileLeftPart from './chevron-mobile-left-part/chevron-mobile-left-part';
import { ChevronProps } from '../../shared/props-types/chevron.props';

const ChevronMobile = ({ chevron }: ChevronProps) => {
  return (
    <div className="chevron-mobile">
      <div className="chevron-mobile-parts">
        <div className="chevron-mobile-left-part-wrapper">
          <ChevronMobileLeftPart chevron={chevron} />
        </div>
        <div className="chevron-mobile-right-part-wrapper">
          <ChevronMobileLeftPart chevron={chevron} />
        </div>
      </div>
    </div>
  );
};

export default ChevronMobile;
